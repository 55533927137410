import { db } from "../firebase"

const backendURL = "https://suits-backend.vercel.app"

export const AppointReq = async(data)=>{
    try{
        const headers = {
            "Content-Type" : "application/json"
        }        


        const resp = await fetch(`${backendURL}/sendMail`, {method : 'POST',headers:headers,body:JSON.stringify({ ...{type:2}, ...data })})
        const res = await resp.json()

        return res.status === 0 ? true : false
    }catch(e){
        console.log(e)
    }
}


export const senEmail = async (params)=>{
        
    const headers = {
        "Content-Type" : "application/json"
    }
    const resp = await fetch('https://suits-backend.vercel.app/sendMail', {method : 'POST',headers:headers,body:JSON.stringify(params)})
    const res = await resp.json()

    return res.status === 0 ? true : false


}


export const postOrder = async(order)=>{

    try{
        console.log("POST Order")

        let orders = JSON.parse(localStorage.getItem('sel'))

        const address = JSON.parse(localStorage.getItem('a'))

        console.log("Orders" , orders)

        if(orders){ 
            // // console.log("Checking order details",order)
            for(let i = 0;i<orders.length;i+=1){
                orders[i]["payment_order_id"] = order["razorpay_order_id"]
                orders[i]["payment_id"] = order["razorpay_payment_id"]
                orders[i]["Address"] = address
            }
            // console.log("dumping in DB", orders)
            
            const collection = db.collection('Orders')
            var batch = db.batch();
            // console.log("passing this")
            const email = order.email
            const curr = new Date()

            const OrderID = curr.getTime().toString()+"M"
            var i = 0
            orders.forEach((doc) => {
                i = i+1
                const ID = (new Date().getTime()).toString()
                // console.log("ID from orders, ", ID)
                const finalOrder = {...doc, ...{"Date":Date(), "id":ID, "email":email, "orderId":OrderID}}
                batch.set(collection.doc(ID), finalOrder);
            })
            const result = await batch.commit()
            
            // paymentStatus({...payment, "id":1})

            localStorage.removeItem('sel')
            localStorage.removeItem('a')

            const pramsData = {
                "email" : email,
                "name" : address.name,
                "orderId" :  OrderID ,
                "items" : i.toString(),
                "price" : order["price"]
            }
            // setcheck({...checkoutData, ...pramsData})
            
            console.log("User Detaisl", pramsData)
            
            senEmail(pramsData).then(()=>{})
            // setLoading(false)
            // console.log("postOrder", result)

            return {...pramsData, ...{status: 1 } }
        }else{
            // console.log("No orders")
            // paymentStatus({...payment, "id":-1})
            // return error
            return {status: 0 } 
        }
    } catch(e){
        return {status: 0 } 
        // console.log("Error",e)
        // paymentStatus({...payment, "id":-1})
    }
}


export const paymentVerification = async(response, atype = 0)=>{
    try{
        // console.log("Verification : ", response)
        const params = {
            "razorpay_payment_id": response.razorpay_payment_id,
            "razorpay_order_id": response.razorpay_order_id
        }
        const headers = {
          "x-razorpay-signature":  response.razorpay_signature,
          "Content-Type" : "application/json"
        }

        const options = {
            method : 'POST'
        }
        
        
        const resp = await fetch(`${backendURL}/verification`, {method : 'POST', headers:headers, body:JSON.stringify(params)})
        const res = await resp.json()
        
        return {...res, ...params};
        // console.log("after payent",res)
        // console.log("Payment Status : ", {
        //     "id" : 0, 
        //     "status": res["status"] === 200
        // })
        // paymentStatus({
        //     "id" : 0, 
        //     "status": res["status"] === 200
        // })
        
        
    }catch(e){
        // console.log("verification e", e)
    }
}








