import React, { useState, useEffect } from 'react';
import './App.css';
import phone from './assets/phone.svg';
import cart from './assets/cart.svg';
import acc from './assets/acc.svg';
import Logo from './assets/Logo.png';
import Logo1 from './assets/download.svg';

import burger_icon from './assets/icon/burger_left.svg'

import { useNavigate } from 'react-router-dom';
import { logout, getState } from './Firebase/auth';
import MobileNavBar from './MobileNavbar';
import Patti_home from './Homepage/Home/patti';
import SidebarNav from './navslider';

export default function NavBar({ flag = 1 }) {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const [sidebar, setSideBar] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const accountClick = () => {
    if (getState()) {
      navigate('/profile');
    } else {
      navigate('/login');
    }
  };

  return (
    <div
      className= {`NavBar ${flag ===3 ? 'patti_nav':''} `}
      style={
        (flag === 0 || flag === 3)  ? { background: 'transparent' } : flag === 2 ? { position: 'fixed' } : {}
      }
    >
      
      <div className='nav_wrapper' style={sidebar? {display:"block", "opacity":"1"}:{}} 
        onClick={()=>{setSideBar(false)}}
      ></div>
        <SidebarNav sidebar={sidebar} setSideBar ={setSideBar} />
      
      
      {flag ===3 && <Patti_home/>}
      <div className="Navbody txt-font">
        {isMobile ? (
          // Mobile View
          <MobileNavBar sidebar={sidebar} setSideBar ={setSideBar} />
        ) : (
          // Desktop View

          
          <div className="nav-desktop">
            <div className="nav-left txt-font1">
              <div type="button">
                <img src={burger_icon} style={{width:"30px"}} type="button"
                  onClick={()=>{setSideBar(!sidebar)}}
                />
                
                {/* Home */}
              </div>
              {/* <div onClick={() => navigate('/')} type="button">
                About Us
              </div>
              <div onClick={() => navigate('/feel')} type="button">
                Feel Fabric
              </div> */}
            </div>

            <div>
              <img src={Logo1} alt="Logo" className='logo_main' onClick={()=>{navigate('/');}} type="button" />
            </div>

            <div className="nav-right txt-font1">
              <img src={phone} className="nav-logos" alt="Phone" 
                type="button"
                onClick={()=>{navigate('/contact');}}
              />
              <img
                type="button"
                src={cart} className="nav-logos" alt="Cart"
                onClick={() => navigate('/cart')}
              />
              <img
                type="button"
                src={acc} className="nav-logos" alt="Account"
                onClick={accountClick}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
