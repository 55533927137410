import { useEffect, useMemo, useRef, useState } from "react"
// import { useHistory } from 'react-router-dom'


import SelectFabric from "./Select/selectFabric"
import ViewFabric from "./View/viewFabric"
import "./fabric.css"
import youtube from '../assets/Arrow/yt.png'

import m_logo from '../assets/measur.svg'
import close from '../assets/icon/close_s.png'

import dArrow from '../assets/icon/darrow_w.png'
import uArrow from '../assets/icon/uparrow_w.png'

import barrowL from '../assets/Arrow/barrowL1.png'
import barrowR from '../assets/Arrow/barrowR.png'

import bclose from '../assets/Arrow/bclose.png'

import lArrow from '../assets/ALeft.png'
import rArrow from '../assets/ARight.png'

import logo_name from '../assets/logo_name.svg'

import raymond from '../assets/icon/Raymond.svg'

import aim from '../assets/samples/anima.gif'

// import {Fabrics_all} from '../Data/index'
import { fabricFilter, StylesFilter} from '../Data/index'
import StyleP from "./Style"
import Measurement from "../Measurements"
import NavBar from "../Navbar"
import { useMatch, useNavigate } from "react-router-dom"
import { getDatafromDoc, getDocOnCondition, getFabricDetails } from "../Firebase/firestoreGet"

import { Types } from "../Data/index"
import FilterFabric from "./Select/Filter"
import { db } from "../firebase"

export default function Fabric(){

    const navigate = useNavigate()
    // const history = useHistory()

    const [Fabrics_all, setFabricsAll] = useState({})
    const [useFabric, setUseFabric] = useState({})

    const [Fabric_same, setFabricSame] = useState()
    

    const [nav, setNav] = useState(-1);
    const [loading, setLoading] = useState(true)

    const [clickHover, setClickHover] = useState(0)

    const [state, setStates] = useState(1)
    const [single, setSingle] = useState([])

    const [section, setSection] = useState(0)
    
    const [fabSame, setFabSame] = useState(true)

    const [indication, setIndication] = useState(false)

    const [jodh, setJodh] = useState(false)

    const [LoadFabInfo, setFabricInfoLoad] = useState(true)

    const [STYLESALL, setStylesAll] = useState({
        "Coat":{},
        "Vest":{},
        "Shirt":{},
        "Pant":{}
    })

    const fabricIcons = useMemo(()=>[
        {des:"Color", "attr":"color", "typ":1},
        {des:"Pattern", "attr":"pattern", "typ":0},
        {des:"Composition","attr":"material", "typ":0},
        {des:"Weave", "attr":"weave", "typ":1},
        {des:"Width", "attr":"width", "typ":1}
    ], [])
    const [sel, setSel] = useState({ 
        "fabric":{"name":"White Wool", "image":"", "price":""},
        "style":{},
        "price":"",
        "name":"Custom Suit",
        "type":1
    })

    const [FabricTypes, setFabricTypes] = useState()
    const [fabricType,setType ] = useState("")

    const [hoverDetils, setHoverDets] = useState({})
    const [sHover, setHover] = useState(-2)
    const [edit , setEdit] = useState(false)

    const [hDes, setHdes] = useState(true)
    const [himg, setHimg] = useState(0)

    const [menuOpen2, setMenuOpen2] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);

    const [imgState, setImgState] = useState(0)

    const [mess, setMess] = useState(true);

    const getFabricDetails1 = async(id)=>{

        // /Fabric/Suits/fabrics
        const coll = db.collection("Fabric").doc("Suits").collection("fabrics")
        const data  = await getDocOnCondition(coll,"id", id )
        // // // // console.log("From function :", id, data)
        return data[0]
    }

    function backMove () {
        // console.log("Curr nav", nav)
        if(section === 0){
            // console.log("executing this")
            setNav((curr)=> curr-1)
        } else {
            // console.log("executing 0")
            setSection(0)
        }
        
        
    }
    const counter = useRef(0)

    const imageLoaded = (c = 1) => {
        counter.current += 1;
        let L = 1;
        
        if (counter.current >= L) {
            // console.log("Removing")
            setFabricInfoLoad(false);
            counter.current = 0;
        }
    }

    useEffect(()=>{
        window.history.pushState( 'dummy', document.title, window.location.href )


        
        window.addEventListener('popstate', backMove);
        
        
        // console.log("someting", nav, window.history)

        // router.subscribe((state) => {
        //     if (state.historyAction === NavigationType.Pop) {
        //         // do work 
        //     }
        // });

    }, [])

    useEffect(()=>{
        if(FabricTypes){
            if(imgState <=2){
                setType("Coat")
            }else{
                if(FabricTypes.includes("Shirt")){
                    if(imgState === 3){
                        setType("Shirt")
                    } else if(imgState <= 5){
                        setType("Pant")
                    } else{
                        setType("Vest")
                    }

                    
                }else{
                    if(imgState <= 4){
                        setType("Pant")
                    } else{
                        setType("Vest")
                    }
                    
                }
            }
        }
        
    }, [imgState])
    useEffect(()=>{
        
        if( hoverDetils && Object.hasOwn(hoverDetils, "q")){
            
            getFabricDetails1(hoverDetils["q"]).then((data)=>{  
                // console.log("From inside promise :", data)
                var Data = {...data}
                var s = ""
                Data.color.forEach((ele)=>{
                    if(ele.length > 0){
                        s = s + ele
                    }
                })
                Data["color"] = s
                setHoverDets(Data)
            }).catch((e)=>{
                // console.log("from useEffect", e)
            })
        }
    }, [hoverDetils])
    
    function sleep(miliseconds) {
        var currentTime = new Date().getTime();
     
        while (currentTime + miliseconds >= new Date().getTime()) {
        }
     }

    const getStyleImg = async(inp = null, l = 0)=>{
        try{
            // setOn(0)
            setLoading(true)
            var Obj = {}
            if(inp){
                Obj = {...inp}
            }else {
                Obj = {...sel}
            }
            
            // console.log("THIS IS BEING USED :", Obj)
            // var Obj = {...sel}
            let styles = Obj.style

            const styleTypes = Object.keys(styles)            
            
            // let Obj = {...sel}
            var StylesALL = {
                "Coat":{},
                "Vest":{},
                "Shirt":{},
                "Pant":{}
            }
            if(!inp){
                let StylesALL = {...STYLESALL}
            }
            
            // console.log("Styles: ", StylesALL)
            for(let j = 0;j<styleTypes.length;j++){
                Obj["fabric"][styleTypes[j]]["single"] = []
                const refString = `Fabric/Suits/${styleTypes[j]}_style/${Obj["fabric"][styleTypes[j]]["id"]}`
                const ref = db.doc(refString)

                // Obj["fabric"][styleTypes[j]]["style"]
                // console.log(styleTypes[j], "this is what its taking from ", Obj["fabric"][styleTypes[j]])
                // console.log("ref", ref)
                
                let Doc = styles[styleTypes[j]]
                for(let k = 0;k<Object.keys(Doc).length;k++){
                    const element = Object.keys(Doc)[k]

                    let name = ""
                    
                    Doc[element].forEach((id)=>{
                        name+=id+"-"
                    })
                    if(Doc[element].length === 1){
                        name = Doc[element][0]
                    }
                    if (name[name.length - 1] === "-") {
                        name = name.slice(0, name.length - 1);
                    }
                    if(styleTypes[j] === "Coat" && name[0] === "J"){
                        name = name.slice(0, 2)+ "-"+name.slice(name.length-2)
                        // console.log("Jodhpuri :", name)
                        Obj["style"]["Coat"]["F"][1] = "0"
                    }
                    if(styleTypes[j] === "Coat" && name.length > 5 && Obj["style"]["Coat"]["F"][1] === "0"){
                        // console.log("Changing the default lapel")
                        Obj["style"]["Coat"]["F"][1] = "N"
                        var t = ""
                        for(let i=0;i<name.length;i++){
                            if(name[i] === "0"){
                                t = t + "N"
                                
                            }else{
                                t = t+ name[i]
                            }
                        }
                        name = t
                    }
                    // console.log("Name ", name)
                    if (styleTypes[j] === "Coat" && name.length > 2) {
                        if (StylesALL[styleTypes[j]]["d-" + name]) {
                            Obj["fabric"]["Coat"]["src"] = StylesALL[styleTypes[j]]["d-" + name];
                        } else {
                            const src = (await getDatafromDoc(ref.collection("display").doc(name)))["src"];
                            StylesALL[styleTypes[j]]["d-" + name] = src;
                            if (src && src.length > 0) {
                                Obj["fabric"]["Coat"]["src"] = src;
                            }
                        }
                    }
                    
                    if(styleTypes[j] === "Shirt"){
                        const L = name.split("-")
                        let tot = L.length
                        let check = -1
                        if(L[L.length-1] === "C"){
                            check = L.length-2
                        }
                        let shirtImgs = []
                        for(let m = 0;m<tot;m++){
                            if(L[m] !="0" && (m!=check) ){
                                // console.log("Shirt running for : ",L[m])
                                if(StylesALL[styleTypes[j]] && !Object.hasOwn(StylesALL[styleTypes[j]], L[m])){
                                    // console.log("Shirt : ", ref, L[m], Obj["fabric"][styleTypes[j]])
                                    const src= (await getDatafromDoc(ref.collection("style").doc(L[m])))["src"]
                                    if(src && src.length > 0){
                                        StylesALL[styleTypes[j]][L[m]] = src 
                                    }                                    
                                } 

                                shirtImgs.push(StylesALL[styleTypes[j]][L[m]])
                            }
                        }
                        Obj["fabric"][styleTypes[j]]["single"].push({
                            "typ": 1,
                            "src": shirtImgs
                        });
                    }

                    if (StylesALL[styleTypes[j]] && !Object.hasOwn(StylesALL[styleTypes[j]], name) && styleTypes[j] !== "Shirt") {
                        if (name && name.length > 0) {
                            const src = (await getDatafromDoc(ref.collection("style").doc(name)))["src"];
                            if (src && src.length > 0) {
                                StylesALL[styleTypes[j]][name] = src;
                            }
                        }
                    }

                    if (styleTypes[j] !== "Shirt") {
                        Obj["fabric"][styleTypes[j]]["single"].push({
                            "typ": 0,
                            "src": StylesALL[styleTypes[j]][name]
                        });
                    }
                }
            }

            setStylesAll(StylesALL);
            setSel(Obj);
            // console.log("--------------Final sel Object", Obj)
            // setOn(1);
            // sleep(1500)
            if(l === 1){
                setLoading(false);
            }
            
            return 1;
        } catch (e) {
            // setOn(1);
            setLoading(false);
            // console.log("from style fetching", e);
            return 0;
        }
    };

    const getIndx= (arr, val, attr)=>{

        for(var i=0;i<arr.length;i++){
            if(arr[i][attr] === val){
                return i
            }
        }
        return -1
    }
    function sortByKey(array, key) {
        return array.sort(function(a, b) {
            var x = a[key]; var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }
    const getSameFabric=()=>{
        var same_fabric = {...Fabrics_all}
        var K = Object.keys(same_fabric)

        for(let i=0;i< K.length ;i++){
            same_fabric[K[i]] = sortByKey(same_fabric[K[i]], "fabricID")
        }
        setFabricSame(same_fabric)
        
    }
    useEffect(()=>{
        console.log("SEL is getting changed somewhere...")
    }, [sel])


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 480);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(()=>{
        if(nav<-1){
            navigate("/")
        }
    }, [nav])
    
    // const computeDistount=  (obj)=>{
    //     for
    // }
    useEffect(()=>{
        let i = 0;
        let Single = []
        if(sel && sel.types){

            sel.types.forEach(element => {
                if(sel.fabric[element] && Object.hasOwn(sel.fabric[element], "single")){
                    i += sel.fabric[element]["single"].length
                    sel.fabric[element]["single"].forEach(val =>{
                        if(val["src"]){
                            Single.push(val)
                        }else{
                            Single.push({"src":val, "typ":0})
                        }
                        
                    })
                }
            });

            // console.log(Single, i)
            setStates(i)
            setSingle(Single)
        }
        
     }, [sel])

    useEffect(()=>{
        if(FabricTypes){
            setLoading(true)
            const defaultStyle = {
                "Coat":{
                    "F":["2B", "N", "FL"],
                    "B":["NV"]
                },
                "Pant":{
                    "F":["F-NP"],
                    "B":["B-SP"]
                },
                "Vest":{
                    "F":["SB"]
                },
                "Shirt":{
                    "F":["BASE", "P1", "SS", "SLEVE", "N"]
                }
            }

            // console.log("Types selecetd", FabricTypes)
            getFabricDetails(FabricTypes).then((data)=>{

                var same_fabric = {...data}
                var K = Object.keys(same_fabric)

                console.log(data)
                for(let i=0;i< K.length ;i++){
                    if(K[i] !== "Shirt"){
                    same_fabric[K[i]] = sortByKey(same_fabric[K[i]], "fabricID")}
                }
                // setFabricSame(same_fabric)
                setFabricsAll(same_fabric)
                setUseFabric(same_fabric)

                // console.log("Fabrics", same_fabric)
                let add = {}
                let defStyle = {}
                const defFabric = data["Coat"][0]["fabricID"]

                Object.keys(data).forEach((ele)=>{
                    
                    const selectEle = data[ele].find((fabric) => fabric.fabricID === defFabric);
                    if(selectEle){
                        // console.log("found same", selectEle["fabricImg"])
                    }
                    add[ele] = selectEle ? selectEle : data[ele][0]
                    add[ele]["pos"] = Types[ele]
                    
                })
                FabricTypes.forEach((typ)=>{
                    defStyle[typ] = defaultStyle[typ]
                })
                if(!edit){

                    let p = 0
                    let D = Number(0)
                    Object.keys(add).forEach((element)=>{
                        if(add[element]["discount"]){
                            const perc = Number(add[element]["discount"])
                            D = D + (Number(add[element]["price"])*( perc/100 ) )
                        }
                        p+=Number(add[element]["price"])
                    }) 

                    // let price = 0
                    // Object.keys(add).forEach((ele)=>{
                    //     price+=Number(add[ele]["price"])
                    // })
                    // // console.log("Initial", add)
                    
                    // setSel(
                    //     {...sel, ...{"fabric":add, "price":p,"discount":D, "style":{...defStyle}}}
                    // )
                    getStyleImg({...sel, ...{"fabric":add, "price":p,"discount":D, "style":{...defStyle}}}).then(()=>{

                    })
                    // console.log("from Refreshing", {...sel, ...{"fabric":add, "price":p,"discount":D, "style":{...defStyle}}}, defStyle, defaultStyle)
                }
                
                // console.log("From main fabric", same_fabric)
            }).catch((e)=>{console.log(e)})
        } else{
            console.log("calling none")
        }
    }, [FabricTypes])
    

    const proceedToViewFabric = () => {
        setNav(1); 
    };
    const proceedToViewFabric2 = () => {
        setNav(-1); 
    };

    // useState(()=>{
    //     const session = JSON.parse(sessionStorage.getItem('sel'))
    //     if(session){
    //         setNav(0)
    //         setFabricTypes(session.types)
    //         setEdit(true)
    //         setSel(session)
    //     }
    // }, [])

    
    

    return(
        <div className="Fabric"
            style={ (nav<=1 || section === 0)? {"overflow":"hidden"} : { "overflowX":"hidden" }}
        >

            
            
            { nav<=1 && nav>-1 && <div className="s-navigation"
                style={nav>1 ? {"top":"90px"}:{}}
            >
                
                <div className={`s-nav_list small-font ${isMobile ? 'mobile-nav-fab' : ''}`}>
                  
            
    
        <>
            <label className={`${nav >= 0 ? "s-nav-shade" : "s-nav"}`}
                onClick={(handleNavClick) => { setLoading(true); 
                    // console.log("setting"); 
                    setNav(0); }}
            >
                Fabric
            </label>
            <div className="nav-line"></div>
            <label className={`${nav === 1 ? "s-nav-shade" : "s-nav"}`}
                onClick={() => { setNav(1); 
                    // console.log("changing"); 
                }}
            >
                Style
            </label>
            <div className="nav-line"></div>
            <label className={`${nav === 2 ? "s-nav-shade" : "s-nav"}`}
                onClick={() => { setNav(2); 
                    // console.log("changing"); 
                }}
            >
                Measure
            </label>
            {/* <label className={`${nav === 2 ? "s-nav-shade" : ""}`}
                onClick={() => { setNav(2); }}
            >
                <img src={m_logo} className={isMobile ? 'mobile-image2' : ''} />
            </label> */}
        </>
    
</div>

            
                </div>}

                {/* //// Logo */}
                {nav<=1 && <div className="s-logo verti_center txt-font"
                    style={{"zIndex":"101"}}
                    onClick={()=>{
                        navigate("/")
                    }}
                    type="button"
                >
                    <img src={logo_name} style={{"width":"150px"}} />
                    
                </div>}

                {/* //// Indication */}

                { (nav === 1 && indication && sel && sel.types && sel.types.includes("Shirt") ) && <>
                    <div className="f-back-dark"
                        onClick={()=>{
                            if(isMobile){
                                setMenuOpen(0)
                                setMenuOpen2(0)
                            }
                        }}
                    >
                    </div>
                    <div className="indication both_center"
                        onClick={()=>{
                            if(isMobile){
                                setMenuOpen(0)
                                setMenuOpen2(0)
                            }
                        }}
                    >
                        <div style={{"width":"100%", "position":"relative", "height":"100%"}}>
                            Hello Again !! <br/>
                            You are purchasing Shirt and selecting  <b>JodhPuri</b> as your Coat type. 
                            Shirts are not visible with Jodjpuri. You can continue to purchase if you wish to. 
                            <div style={{"display":"flex", "gap":"10px", "fontSize":"0.8rem", "marginTop":"30px"}}>
                            <button className="Butt1" 
                                onClick={()=>{setIndication(false)}}
                            > <span>
                                Continue</span> </button>
                            <button className="Butt1 orange_button" 
                                onClick={()=>{ setNav(-1) }}
                            > <span>Change Selection</span> </button>
                            </div>
                        </div>
                    </div>
                </>
                }

                
                

            
            {
            
            nav === -1 ? (<div className="SelectionBody">
            
              {isMobile ? (
                        <div className="select-fabric-container mobile-view">
                            
                            <SelectFabric
                                Fabric={Fabrics_all}
                                useFabric = {useFabric}
                                setUseFabric = {setUseFabric}
                                sel={sel}
                                setSel={setSel}
                                sHover={setHoverDets}
                                hoverState={setHover}
                                hover={sHover}
                                nav={nav}
                                setNav={setNav}
                                setFabricsAll={setFabricsAll}
                                setFabricTypes={setFabricTypes}
                                FabricTypes={FabricTypes}

                                menuOpen2={menuOpen2}
                                setMenuOpen2={setMenuOpen2}
                                menuOpen = {menuOpen}
                                clickHover={clickHover}
                                setClickHover={setClickHover}

                                getStyleImg = {getStyleImg}
                                setStylesAll = {setStylesAll}

                                imgState = {imgState}
                                setImgState = {setImgState}

                                fabSame={fabSame}
                                setFabSame={setFabSame}
                                fabricType = {fabricType}
                                setType = {setType}
                            />
                            
                        </div>
                        
                    ) : (
                        <SelectFabric
                            Fabric={Fabrics_all}
                            useFabric = {useFabric}
                            setUseFabric = {setUseFabric}
                            sel={sel}
                            setSel={setSel}
                            sHover={setHoverDets}
                            hoverState={setHover}
                            hover={sHover}

                
                            nav={nav}
                            setNav={setNav}
                            setFabricsAll={setFabricsAll}
                            setFabricTypes={setFabricTypes}
                            FabricTypes={FabricTypes}

                            menuOpen2={menuOpen2}
                            setMenuOpen2={setMenuOpen2}
                            menuOpen = {menuOpen}

                            clickHover={clickHover}
                            setClickHover={setClickHover}

                            getStyleImg = {getStyleImg}
                            setStylesAll = {setStylesAll}

                            imgState = {imgState}
                            setImgState = {setImgState}

                            fabSame={fabSame}
                            setFabSame={setFabSame}

                            fabricType = {fabricType}
                            setType = {setType}
                        />
                    )}
                  
                    
            </div>)  

            :nav == 0 ?  
            
                (<div className="fabricbody">
                    
                    
                    {/* Fabric page Filter */}
                    {true && (
                        <FilterFabric/>
                    )}

                    {/* Fabric hover details */}
                    {sHover == -1 && (
                        <div className="f-back-dark">
                        </div>
                    )
                    }
                    { (sHover == -1) && hoverDetils && (<div className="s-modal"
                        style={{"backgroundColor":"white"}}
                    >
                        {/* <div style={{"position":"relative", "width":"100%", "height":"100%", "backgroundColor":"white", "z-index":"1000"}}></div> */}
                        
                        {hoverDetils.link && hoverDetils.link.length > 0 && 
                           <a href={hoverDetils.link}>
                           <img style={{"position":"absolute", "left":"5px", "top":"5px", "zIndex":"1000", "width":"50px"}} src={youtube}/>
                           </a>
                        }
                        
                        <div style={{"position":"absolute", "backgroundColor":"white", "width":"25px", "left":"10px", "zIndex":"999"}} >  </div>
                        <div
                            onClick={()=>{
                                setHdes(true)
                                setHimg(0)
                                setHoverDets({})
                                setHover(-2)
                            }}
                        >
                        
                        <img 
                            type="button"
                        src={bclose} style={{right:"15px", "top":"15px", position:"absolute", "zIndex":"1", "width":"40px"}}/>
                        
                        </div>
                        
                        {/* {LoadFabInfo && (<div className='f-loading'
                            style={{"position":"absolute"}}
                        >
                            <img alt src={aim} onerror="this.src='https://d1fufvy4xao6k9.cloudfront.net/images/garment/loading.gif'"/>
                        </div>)} */}
                        { hoverDetils['single'] && hoverDetils['single'].length>0 ? 
                            (<>
                                {himg === 0 ? 
                                    (<img 
                                        // onLoad={imageLoaded}
                                        src={hoverDetils['single'][0]} className="m-main-img"/>)
                                : himg === 1 ? 
                                (
                                    <img 
                                    // onLoad={imageLoaded}
                                    src={hoverDetils['src']} className="m-main-img"/>
                                ) : 
                                (<img 
                                    // onLoad={imageLoaded}
                                    src={hoverDetils['single'][himg-1]} className="m-main-img"/>)
                                }
                            </>) 
                            : 
                            (
                                <img 
                                onLoad={imageLoaded}
                                src={
                                    hoverDetils['src']}
                                className="m-main-img"/>
                            )
                        }

                        

                        <img  src={barrowL} className="m-f-img-l"
                            type="button"
                            onClick={()=>{

                                if(himg>0){
                                    // console.log("left click")
                                    setHimg(himg-1)
                                }
                            }}
                        />
                        <img src={barrowR}  className="m-f-img-r"
                            type="button"
                            onClick={()=>{
                                if(himg < hoverDetils['single'].length){
                                    // console.log("Right click")
                                    setHimg(himg+1)
                                }
                            }}
                        />
                        
                        <div className="s-hoverDes">
                            <img src={hDes ? `${dArrow}` : `${uArrow}`} className="m-f-arrow hori_center"
                                type="button"
                                onClick={()=>{setHdes(!hDes)}}
                            />
                            {(hDes) ? (<>
                            <div className="s-h-container">
                            <div className="s-h-des-1">
                                <label className="txt-font">{hoverDetils["name"]}</label>
                                <label className="txt-font1">by</label>
                                <img src={hoverDetils.brandImg} className="s-f-des-img hori_center" />
                                
                                <br/>

                                <label className="txt-small">{hoverDetils["des"]}</label>  
                                
                            </div>
                            <div className="s-h-line hori_center" ></div>
                            <div
                                style={{"width":"10%", "height":"100%"}}
                            ></div>
                            <div className="s-h-icons">
                                {fabricIcons.map((val1, key1)=>{
                                    return(<div style={ isMobile ? {"width":"90%","position":"relative"}:{"width":"90%", "position":"relative"}} className="hori_center">
                                        <label>{val1["des"]}</label>
                                        <label style={{"position":"absolute", "right":"0"}}>
                                        {hoverDetils[val1["attr"]]}
                                        {/* gr/Meter */}
                                        </label>
                                    </div>)
                                })}
                            </div>
                            </div>
                            </>): (
                            <div>
                                
                                <b>{hoverDetils["name"]} , {hoverDetils["brand"]} </b>
                            </div>) }
                        </div>
                    </div>)}

                    {/* {isMobile && (
            
            <div className="burger-menu" onClick={() => setMenuOpen2(!menuOpen2)}>
                        <span className="burger-menu-icon">Fabric</span>
                    </div> )
                    }  */}

            {/* {isMobile && (
                <div className="burger-menu2" onClick={() => setMenuOpen(!menuOpen)}>
                    <span className="burger-menu-icon2">Style</span>
                </div>
            )} */}

                {isMobile && (<div>


                    <StyleP
                        sel = {sel}
                        setSel = {setSel}
                        
                        setNav = {setNav}

                        // Styles = {StylesALL}
                        // setStylesAll = {setStylesAll}
                        getStyleImg = {getStyleImg}
                        setLoad = {setLoading}
                        menuOpen={menuOpen}
                        setMenuOpen={setMenuOpen}

                        setImgState=  {setImgState}

                        setIndication= {setIndication}
                        setJodh = {setJodh}

                        jodh={jodh}
                    />

                    

                   </div>                    
                    )}
                    
                    

                    <SelectFabric
                        Fabric = {Fabrics_all}
                        useFabric = {useFabric}
                        setUseFabric = {setUseFabric}

                        sel = {sel}
                        setSel = {setSel}
                        
                        sHover = {setHoverDets}
                        hoverState = {setHover}
                        hover = {sHover}

                        setNav={setNav}

                        setFabricsAll = {setFabricsAll}
                        setFabricTypes= {setFabricTypes}
                        FabricTypes = {FabricTypes}

                        menuOpen2={menuOpen2}
                        setMenuOpen2={setMenuOpen2}
                        menuOpen = {menuOpen}

                        clickHover={clickHover}
                        setClickHover={setClickHover}

                        getStyleImg = {getStyleImg}
                        setStylesAll = {setStylesAll}

                        imgState = {imgState}
                        setImgState = {setImgState}

                        fabSame={fabSame}
                        setFabSame={setFabSame}
                        
                        fabricType = {fabricType}
                        setType = {setType}
                    />


                    
                    <ViewFabric
                        sel = {sel}
                        setSel = {setSel}
                        nav = {nav}
                        setNav = {setNav}

                        loading ={loading}
                        setLoading={setLoading}

                        single = {single}
                        state={state}

                        setStylesAll = {setStylesAll}
                        
                        setMenuOpen = {setMenuOpen}
                        menuOpen = {menuOpen}

                        setMenuOpen2 = {setMenuOpen2}
                        menuOpen2 = {menuOpen2}

                        imgState = {imgState}
                        setImgState = {setImgState}

                        fabricType = {fabricType}
                        setType = {setType}

                        jodh = {jodh}

                        mess = {mess}
                        setMess = {setMess}
                    />

                    
                    
                </div>)
            : nav == 1 ?
            
                (<div className="StyleBody">
                    
                    {/* {isMobile && (
            
            <div className="burger-menu" onClick={() => setMenuOpen2(!menuOpen2)}>
                        <span className="burger-menu-icon">Fabric</span>
                    </div> )}  */}

{/* {isMobile && (
                <div className="burger-menu2" onClick={() => setMenuOpen(!menuOpen)}>
                    <span className="burger-menu-icon2">Style</span>
                </div>
            )} */}
            

                    {isMobile && (
                        <SelectFabric
                        Fabric = {Fabrics_all}
                        useFabric = {useFabric}
                        setUseFabric = {setUseFabric}

                        sel = {sel}
                        setSel = {setSel}
                        
                        sHover = {setHoverDets}
                        hoverState = {setHover}
                        hover = {sHover}

                        setNav={setNav}

                        setFabricsAll = {setFabricsAll}
                        setFabricTypes= {setFabricTypes}
                        FabricTypes = {FabricTypes}

                        menuOpen2={menuOpen2}
                        setMenuOpen2={setMenuOpen2}
                        menuOpen = {menuOpen}
                        
                        clickHover={clickHover}
                        setClickHover={setClickHover}

                        getStyleImg = {getStyleImg}
                        setStylesAll = {setStylesAll}
                        
                        imgState = {imgState}
                        setImgState = {setImgState}

                        fabSame={fabSame}
                        setFabSame={setFabSame}
                    
                        fabricType = {fabricType}
                        setType = {setType}
                    />
                    )}
                    
                    <StyleP
                        sel = {sel}
                        setSel = {setSel}
                        
                        setNav = {setNav}

                        // Styles = {StylesALL}
                        // setStylesAll = {setStylesAll}
                        getStyleImg = {getStyleImg}
                        setLoad = {setLoading}
                        menuOpen={menuOpen}
                        setMenuOpen={setMenuOpen}

                        setImgState=  {setImgState}

                        setIndication= {setIndication}

                        setJodh = {setJodh}

                        jodh={jodh}
                    />

                    <ViewFabric
                        sel = {sel}
                        nav = {nav}
                        setSel = {setSel}
                        setNav = {setNav}

                        loading ={loading}
                        setLoading={setLoading}

                        single = {single}
                        state={state}
                        
                        setStylesAll = {setStylesAll}

                        setMenuOpen = {setMenuOpen}
                        menuOpen = {menuOpen}

                        setMenuOpen2 = {setMenuOpen2}
                        menuOpen2 = {menuOpen2}

                        imgState = {imgState}
                        setImgState = {setImgState}

                        fabricType = {fabricType}
                        setType = {setType}

                        jodh = {jodh}

                        mess = {mess}
                        setMess = {setMess}
                    />
                </div>)
            : nav === 2 ?
                (<>
                    <NavBar
                        flag={1}
                    />
                    <Measurement
                        nav = {nav}
                        setNav = {setNav}
                        section = {section}
                        setSection = {setSection}
                        type={1}
                    />
                </>
                
                )
            : (<> Nothing Found</>)

            }

                
        </div>
    )

}