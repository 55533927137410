import { useEffect, useState } from "react";
import Cartcard from "./cartCard";
import { useNavigate } from "react-router-dom";

import Switch from '@mui/material/Switch';

import fabric_src from '../assets/samples/SAMPLE.png'

import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

function Cart({setChange, modal_item ,setModalItem, cartItems, setCart, deleteFromCart, inCart, setInCart }) {
    const navigate = useNavigate()

    const [toggle, setToggle] = useState(false)

    

    useEffect(()=>{
        // console.log(cartItems)
        if(cartItems && cartItems.length){
            generateCart()
        }
        
    }, [toggle, cartItems])

    const generateCart = ()=>{
        try{
            let carts = []
            let FabricsCart = []


            for(let i =  0;i<cartItems.length;i++){

                if(toggle && ( !Object.keys(cartItems[i]).includes("item_type"))){
                    const typs  = cartItems[i].types
                    typs.forEach(element => {
                        carts.push({
                            "item_type":2,
                            "type" : element,
                            "fabric": cartItems[i].fabric[element],
                            "price" : cartItems[i].fabric[element].price,
                            "discount" : cartItems[i].fabric[element].discount ? cartItems[i].fabric[element].discount : 0,
                            "style": cartItems[i].style[element],
                            "measurements":cartItems[i].measurements
                        })    
                    });
                    
                } else{
                    // if(Object.keys(cartItems[i]).includes("item_type") && cartItems[i].item_type === 0){
                    //     FabricsCart.push(cartItems[i])
                    // } else {
                        carts.push(cartItems[i])
                    // }
                    
                } 
            }
            // if(FabricsCart && FabricsCart.length>0){
            //     carts.push({
            //         item_type:0,
            //         src:fabric_src,
            //         name : "Fabric Sample Set",
            //         items : FabricsCart,
            //         id : (new Date().getTime()).toString(),
            //         price : FabricsCart.length>=2 ? 250 : 100
            //     })
            // }
            // console.log("From cart :", carts)
            setInCart(carts)

        } catch(e){
            console.log(e)
        }
    }

    
    return (
    <div className="Cart">

        

        { cartItems && cartItems.length>0 && <div className="txt-font urcart" style={{"marginBottom":"20px", "display":"flex", "position":"relative"}}>
            Your Cart
            <Tooltip TransitionComponent={Zoom} title="Switch the Toggle to see different View of the Cart. Switch the Toggle off to see the Cloth together and Switch it ON to see the Fabrics independently">
                    {/* <Button>Zoom</Button> */}
                    <div className="txt-font1 cart_switch"
                
                    >Switch Cart <Switch {...label} defaultChecked 
                        // onChange={(e)=>{console.log(e.target.value)}}
                        onChange={() => {
                            // console.log(!fabSame);
                            setToggle(prevCheck => !prevCheck) ; }}
                        value={toggle}
                        checked={!!toggle}
                    /> </div>
        </Tooltip>
            </div>}
        {inCart && inCart.length >0 && inCart.map((value, key)=>{
            return(
                
                <Cartcard item={value} key={key} deleteFromCart={deleteFromCart}
                            toggle = {toggle} setToggle = {setToggle}
                            setModalItem = {setModalItem}
                />
            )
        })
            
        }
        {(!cartItems || (cartItems && cartItems.length == 0)) && (
            <div className="empty_cart">
                <div className="txt-font">Your Shopping Cart is Empty</div>
                <div className="txt-font1">Build your custom suits within few minitues and get it delivered within 14 days !!</div>
                <button className="Butt1"
                    onClick={()=>{
                        navigate("/")
                    }}
                ><span>Shop Now</span></button>
            </div>
        )

        }

    </div>);
}

export default Cart;