import { useRef } from 'react';
import './contact.css'
import contact from '../assets/contact/contact.png'

import { AppointReq } from '../Functions';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Appointment({type=0}) {

    const email = useRef()
    const name = useRef()
    const phone = useRef()
    const org = useRef()
    const des = useRef()
    const n = useRef()

    const notify = () => toast.error("Please Enter all the Required Details", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
        });

    return ( 
    <div className="Appointment"
        style={type ===0 ? {"background":`url(${contact})`, backgroundRepeat:"no-repeat", backgroundSize:"cover" } : { backgroundRepeat:"no-repeat", backgroundSize:"cover"}}
    >
        <div>
                    {/* <button onClick={notify}>Notify!</button> */}
                    <ToastContainer
                        position="bottom-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />
            </div>

        <div className='a-form both_center'>
        <div className=' txt-font'>
            <label className='hori_center' style={{"width":"fit-content"}} >
                <b>Order Request</b>
            </label>
        </div>
        <form>
            <div class="form-group">
                <label for="exampleInputEmail1"><b>Email address     </b> <span style={{color:"red"}}>*</span> </label>
                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" ref={email}/>
                <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
            </div>
            <div className='row_in'>
            <div class="form-group">
                <label for="exampleInputPassword1"><b>Name</b></label>
                <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Name" ref={name}/>
            </div>
            <div class="form-group">
                <label for="exampleInputPassword1"><b>Organization</b></label>
                <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Organization" ref={org}/>
            </div>
            </div>
            <div class="form-group">
                <label for="exampleInputPassword1"><b>Description</b> <span style={{color:"red"}}>*</span> </label>
                <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Description" ref={des}/>
            </div>
            <div className='row_in'>
            <div class="form-group">
                <label for="exampleInputPassword1"><b>Contact Number</b>  </label>
                <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Contact Number" ref={phone}/>
            </div>
            <div class="form-group">
                <label for="exampleInputPassword1"><b>Number of orders <span style={{fontSize:"0.7rem"}}>aprox.</span></b></label>
                <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Number of Orders" ref={n}/>
            </div>
            </div>
            {/* <div class="form-check">
                <input type="checkbox" class="form-check-input" id="exampleCheck1"/>
                <label class="form-check-label" for="exampleCheck1">Check me out</label>
            </div> */}
            <button type="button" class="Butt1 hori_center"
                onClick={async ()=>{

                    if(!email.current.value ||
                        !des.current.value
                    ){
                        console.log("error")
                        notify()
                    } else {

                        
                        const data ={
                            email : email.current.value,
                            des : des.current.value,
                            phone : phone.current.value,
                            orders : n.current.value,
                            name : name.current.value,
                            organization : org.current.value
                        }
                        await AppointReq(data)
                    }
                }}
            ><span>Submit</span></button>
            <div style={{"fontSize":"0.8rem"}}> <span style={{"color":"red"}}>*</span>contact us only if you have minimum order request of 100 </div>
            </form>

        </div>
    </div> );
}

export default Appointment;